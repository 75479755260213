import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import AOS from "aos";
import { useShallow } from "zustand/react/shallow";

import { useAosApply } from "@common/hooks";
import { AppProvider } from "@common/app-provider";
import { useLoginStore } from "explore/store/login.store";

// Note: Don't change the order
import "@common/theme/root.css";
import "@common/theme/global.css";
import "@common/theme/text.theme.css";
import "@common/theme/title.theme.css";
import "@common/theme/global.override.css";
import "@common/theme/theme.css";

import { SuspenseLazyLoader } from "@common/lazy-loader";
import { Loader } from "@components/loader/basic/fullscreen";
import LoginPage from "explore/login";

const ExplorePage = SuspenseLazyLoader(() => import("./explore"), null);

const root = ReactDOM.createRoot(document.getElementById("root"));

const RenderApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { accountInfo } = useLoginStore(
    useShallow((state) => ({ accountInfo: state.accountInfo }))
  );
  const [RenderComponent, setRenderComponent] = useState(null);

  useAosApply(location.pathname);

  useEffect(() => {
    AOS.init({
      offset: 100,
    });
  }, []);

  // Check login status and navigate to /login if necessary
  useEffect(() => {
    if (
      !accountInfo?.projectId &&
      !["/explore/login", "/explore"].includes(location.pathname)
    ) {
      navigate("/explore");
    }
  }, [accountInfo, location.pathname]);

  // Load the builder dynamically based on projectId
  useEffect(() => {
    if (accountInfo?.projectId) {
      const loadBuilder = async () => {
        try {
          let path = `studio-${accountInfo.projectId.split("-")[0]}`;
          if (accountInfo.projectId === "677828c7e1d6ff723583dbc6") {
            path = "studio-super-admin";
          }
          const StudioBuilder = (
            await import(`./builder/studio/${path}/app.jsx`)
          ).default;
          setRenderComponent(() => StudioBuilder);
          console.info("Builder loaded successfully");
        } catch (error) {
          console.error("Failed to load the builder component:", error);
          navigate("/explore");
        }
      };

      loadBuilder();
    }
  }, [accountInfo, navigate]);

  if (!accountInfo?.projectId && location.pathname === "/explore") {
    return <ExplorePage />;
  }
  if (!accountInfo?.projectId && location.pathname === "/explore/login") {
    return <LoginPage />;
  }
  if (
    accountInfo?.projectId &&
    ["/explore/login", "/explore"].includes(location.pathname)
  ) {
    navigate("/");
  }
  if (RenderComponent) {
    return (
      <>
        <RenderComponent />
      </>
    );
  }

  // Fallback while loading
  return <Loader showText={false} />;
};

root.render(
  <BrowserRouter>
    <AppProvider>
      <div className={`studio-theme`}>
        <RenderApp />
      </div>
    </AppProvider>
  </BrowserRouter>
);
