import { Loader } from "@components/loader/basic/fullscreen";
import React, { Suspense, useRef } from "react";

import styles from "./loader.module.css";

export const SuspenseLazyLoader = (importFunc, delay = 1000, loader) => {
  return function WithSuspense(props) {
    const ref = useRef({ loading: true });

    const LazyComponent = React.lazy(async () => {
      const startTime = Date.now();
      const module = await importFunc();
      const endTime = Date.now();
      const loadTime = endTime - startTime;

      const remainingTime = loadTime < delay ? delay - loadTime : 0;

      let extendDelay =
        remainingTime <= 50 ? delay - remainingTime : remainingTime;

      setTimeout(() => {
        ref.current.loading = false;
      }, extendDelay - 10);

      await new Promise((resolve) => setTimeout(resolve, extendDelay));

      console.info(
        `lazy component loaded ${loadTime}ms - remain ${remainingTime}ms at ${delay}ms`
      );
      ref.current.loading = true;
      console.info("-----------------------------------------");

      return module;
    });

    return (
      <Suspense
        fallback={
          loader ? (
            loader(ref.current.loading)
          ) : (
            <div className={`${styles.loader}`}>
              <Loader showText={false} loading={ref.current.loading} />
            </div>
          )
        }
        ref={ref}
      >
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};
